.textInputWrapper {
  margin-bottom: 10px;
}

.textInput {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textInput > label {
  margin-right: 10px;
}

.textInput > input {
  width: 100%;
  max-width: 200px;
  min-width: 150px;
  padding: 6px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  letter-spacing: 1px;
}

.error {
  color: rgb(223, 45, 45);
  font-size: 15px;
  margin-bottom: 10px;
}
