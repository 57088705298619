.wrapper {
  display: flex;
  position: relative;
}
.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
}

.container:hover > .file {
  transform: scale(1.2);
}

.file {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.button {
  position: absolute;
  display: flex;
  border-radius: 100%;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
}

.gridWrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 250px;
  margin: 10px;
  grid-auto-flow: dense;
  position: relative;
}

.wide {
  grid-column: span 2;
}

.tall {
  grid-row: span 2;
}

.big {
  grid-column: span 2;
  grid-row: span 2;
}

.small {
  grid-column: span 1;
  grid-row: span 1;
}

.modal {
  position: fixed;
  display: flex;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
  background-color: #fefefe;
  margin: auto;
  border-radius: 5px;
  position: relative;
}

.modalContent > img {
  padding: 10px;
  display: block;
  max-width: 90vw;
  max-height: 90vh;
}

.modalContent > video {
  padding: 78px 10px;
  display: block;
  max-width: 90vw;
  max-height: 90vh;
}

.videoContainer {
  position: relative;
}

.videoIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  pointer-events: none;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: 15px;
  border-radius: 100%;
  cursor: pointer;
}

.clapButtonDiv {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
  overflow: visible;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  padding-top: 20px;
  font-size: 25px;
  letter-spacing: 5px;
}

.dotPulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  color: black;
  box-shadow: 9999px 0 0 -5px black;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dotPulse::before,
.dotPulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  color: black;
}

.dotPulse::before {
  box-shadow: 9974px 0 0 -5px black;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dotPulse::after {
  box-shadow: 10014px 0 0 -5px black;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9974px 0 0 -5px black;
  }
  30% {
    box-shadow: 9974px 0 0 2px black;
  }
  60%,
  100% {
    box-shadow: 9974px 0 0 -5px black;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px black;
  }
  30% {
    box-shadow: 9999px 0 0 2px black;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px black;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px black;
  }
  30% {
    box-shadow: 10014px 0 0 2px black;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px black;
  }
}

@media only screen and (max-width: 800px) {
  .gridWrapper {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: 150px;
  }
  .modalContent > img {
    max-width: 95vw;
  }
  .modalContent > video {
    max-width: 95vw;
  }
}
