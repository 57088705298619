.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
}

.count {
  background-color: rgba(0, 0, 0, 0.479);
  padding: 0px 5px;
  border-radius: 5px;
  color: white;
  margin-bottom: 2px;
  font-size: 13px;
  line-height: 20px;
}

.button {
  position: relative;
  width: 43px;
  height: 43px;
  overflow: visible;
}

.clap {
  z-index: 801;
  border-radius: 100%;
  width: 43px;
  height: 43px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}

.clap > img {
  width: 38px;
  height: 38px;
  pointer-events: none;
  user-select: none;
  border-radius: 100%;
}

.clap:active {
  transform: rotate(10deg);
}

@keyframes firework {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    width: 120px;
    opacity: 0;
  }
}

.firework {
  opacity: 0;
  z-index: 1800;
  position: absolute;
  pointer-events: none;
  user-select: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  aspect-ratio: 1;
  background: radial-gradient(circle, rgb(0, 60, 255) 0.2vmin, #0000 0) 50% 00%,
    radial-gradient(circle, rgb(255, 255, 255) 0.3vmin, #0000 0) 00% 50%,
    radial-gradient(circle, rgb(107, 125, 170) 0.5vmin, #0000 0) 50% 99%,
    radial-gradient(circle, rgb(169, 126, 192) 0.2vmin, #0000 0) 99% 50%,
    radial-gradient(circle, rgb(255, 255, 255) 0.3vmin, #0000 0) 80% 90%,
    radial-gradient(circle, rgb(111, 87, 127) 0.5vmin, #0000 0) 95% 90%,
    radial-gradient(circle, rgb(114, 119, 194) 0.5vmin, #0000 0) 10% 60%,
    radial-gradient(circle, rgb(255, 255, 255) 0.2vmin, #0000 0) 31% 80%,
    radial-gradient(circle, rgb(144, 189, 202) 0.3vmin, #0000 0) 80% 10%,
    radial-gradient(circle, rgb(255, 255, 255) 0.2vmin, #0000 0) 90% 23%,
    radial-gradient(circle, rgb(128, 183, 210) 0.3vmin, #0000 0) 45% 20%,
    radial-gradient(circle, rgb(255, 255, 255) 0.5vmin, #0000 0) 13% 24%;
  background-size: 4px 4px;
  background-repeat: no-repeat;
  animation: firework 0.5s;
}

.alt1 {
  transform: translate(-50%, -50%) rotate(25deg) !important;
}

.alt2 {
  transform: translate(-50%, -50%) rotate(-37deg) !important;
}
