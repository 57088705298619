.app {
  text-align: center;
  color: #282c34;
  position: relative;
  overflow: hidden;
}

.header {
  border-radius: 5px;
  overflow: hidden;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, 1fr);
  grid-auto-rows: minmax(500px, auto);
  margin: 10px;
  grid-auto-flow: dense;
  position: relative;
}

.hero {
  width: 100%;
  height: 100%;
}

.hero > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}

.title {
  font-family: "Dawning of a New Day", cursive;
  margin: 0;
  top: 0;
  left: 11%;
  font-size: calc(4.6vw);
  position: absolute;
  color: #32433d;
}

.subTitle {
  font-family: "Dawning of a New Day", cursive;
  margin: 0;
  top: 20%;
  left: 10%;
  font-size: calc(4vw);
  position: absolute;
  color: #32433d;
}

.button {
  position: absolute;
  display: flex;
  border-radius: 100%;
  bottom: 5px;
  right: 5px;
}

.login {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.login > img {
  width: 37px;
  height: 37px;
  cursor: pointer;
}

.lang {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}

.lang > img {
  width: 35px;
  height: 35px;
  margin: 10px;
  border-radius: 100%;
  border: 2px solid white;
  cursor: pointer;
}

.fab {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 800;
}

footer {
  padding: 20px 0 30px 0;
}

.copyright {
  font-size: 18px;
}

.hint {
  font-size: 16px;
  margin-top: 5px;
  display: block;
  text-decoration: none;
  color: #282c34;
}

@media only screen and (max-width: 1000px) {
  .subTitle {
    left: 4%;
  }
}

@media only screen and (max-width: 800px) {
  .header {
    grid-auto-rows: minmax(300px, auto);
  }
  .modalContent > img {
    max-width: 95vw;
  }
  .modalContent > video {
    max-width: 95vw;
  }
  .title {
    font-size: 25px;
    top: 10px;
    left: 48px;
  }
  .titleNoMargin {
    left: 20px;
  }

  .subTitle {
    font-size: 25px;
    top: auto;
    bottom: 20px;
    left: 20px;
  }
}
