.container {
  display: flex;
  flex-direction: column;
  width: 500px;
  min-width: auto;
  margin: auto;
}

.title {
  font-size: 25px;
  margin-bottom: 10px;
}

.draggingArea {
  height: 150px;
  border: 3px dashed #222;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  padding: 0px 20px;
  margin-bottom: 10px;
  user-select: none;
}

.imageList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.imageContainer {
  position: relative;
  padding: 10px;
}

.image {
  max-height: 150px;
  max-width: 100%;
}

.buttonRow {
  display: flex;
  margin-bottom: 10px;
}

.spacer {
  width: 20px;
}

.deleteContainer {
  background-color: white;
  border-radius: 100%;
  position: absolute;
  top: 3px;
  right: 3px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.delete {
  width: 20px;
  height: 20px;
  display: block;
}

.loading {
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.61);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
  letter-spacing: 5px;
}

.dotPulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  box-shadow: 9999px 0 0 -5px white;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dotPulse::before,
.dotPulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
}

.dotPulse::before {
  box-shadow: 9974px 0 0 -5px white;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dotPulse::after {
  box-shadow: 10014px 0 0 -5px white;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9974px 0 0 -5px white;
  }
  30% {
    box-shadow: 9974px 0 0 2px white;
  }
  60%,
  100% {
    box-shadow: 9974px 0 0 -5px white;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px white;
  }
  30% {
    box-shadow: 9999px 0 0 2px white;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px white;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px white;
  }
  30% {
    box-shadow: 10014px 0 0 2px white;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px white;
  }
}

@media only screen and (max-width: 600px) {
  .draggingArea {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .buttonRow {
    display: none;
  }
}
