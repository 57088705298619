.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fefefe;
  border-radius: 5px;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 90vw;
  max-height: 90vh;
  padding: 40px;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: 5px;
  border-radius: 100%;
  cursor: pointer;
}

@media only screen and (max-width: 550px) {
  .modalContent {
    max-width: 95vw;
  }
}
