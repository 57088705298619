.fab {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 800;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  user-select: none;
}

.fab:active {
  transform: translateY(2px);
}
